















































































































import { Component, Vue } from 'vue-property-decorator';
import { IUserProfileUpdate, IUserService } from '@/interfaces';
import {
  dispatchGetUser,
  dispatchUpdateUser,
  readAdminOneUser,
  dispatchGetServices,
  readServices,
} from '@/store/admin/accessors';
import VTextFieldWithValidation from '@/components/VTextFieldWithValidation.vue';
import { ValidationProvider } from 'vee-validate';

@Component({
  components: {
    VTextFieldWithValidation,
  },
})
export default class EditUser extends Vue {
  public valid = true;
  public login: string = '';
  public isActive: boolean = true;
  public isSuperuser: boolean = false;
  public setPassword = false;
  public password1: string = '';
  public password2: string = '';
  public services: IUserService[] = [];
  public servicesList: string[] = [];
  public servicesHeaders = [
    {
      value: 'service',
      width: '15%',
    },
    {
      value: 'allowed_senders',
      width: '25%',
    },
    {
      value: 'callback_url',
      width: '25%',
    },
    {
      value: 'callback_token',
      width: '15%',
    },
    {
      value: 'callback_token_header',
      width: '15%',
    },
    {
      value: 'action',
      width: '5%',
    },
  ];
  public currentService: string = '';
  public $refs!: {
    observer: InstanceType<typeof ValidationProvider>;
  };

  public async mounted() {
    await dispatchGetUser(this.$store, Number(this.$router.currentRoute.params.id));
    await dispatchGetServices(this.$store);
    this.reset();
  }

  public reset() {
    this.servicesList = Array.from(readServices(this.$store), (x) => x.name);
    this.setPassword = false;
    this.password1 = '';
    this.password2 = '';
    this.$refs.observer.reset();
    if (this.user) {
      this.login = this.user.login;
      this.isActive = this.user.is_active;
      this.isSuperuser = this.user.is_superuser;
      this.services = this.user.services.map((x) => ({ ...x }));
      for (const service of this.services) {
        this.servicesList.splice(this.servicesList.indexOf(service.service), 1);
      }
    }
  }

  public cancel() {
    this.$router.back();
  }

  public deleteUserService(item) {
    const index = this.services.indexOf(item);
    this.services.splice(index, 1);
  }

  public addUserService() {
    if (this.currentService !== '') {
      this.services.push({
        service: this.currentService,
        check_sender: true,
        allowed_senders: [],
        callback_url: null,
        callback_token: null,
        callback_token_header: null,
      });
      this.servicesList.splice(this.servicesList.indexOf(this.currentService), 1);
      this.currentService = '';
    }
  }

  public async submit() {
    if (await this.$refs.observer.validate()) {
      const updatedProfile: IUserProfileUpdate = {};
      if (this.login) {
        updatedProfile.login = this.login;
      }
      updatedProfile.is_active = this.isActive;
      updatedProfile.is_superuser = this.isSuperuser;
      for (const service of this.services) {
        if (!(service.allowed_senders instanceof Array)) {
          service.allowed_senders = service.allowed_senders.split(',').map((item) => item.trim());
        }
      }
      updatedProfile.services = this.services;
      if (this.setPassword) {
        updatedProfile.password = this.password1;
      }
      const result = await dispatchUpdateUser(this.$store, { id: this.user!.id, user: updatedProfile });
      if (result) {
        this.$router.push('/main/admin/users');
      }
    }
  }

  get user() {
    return readAdminOneUser(this.$store)(+this.$router.currentRoute.params.id);
  }
}
